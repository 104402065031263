<template lang="pug">
bl-section-mv#section-informasi-barang.c-information(:border-top='false', :aside-affix='false')
  template(slot='aside')
    h2.c-product-details-section__aside--sticky Informasi Barang
  template(slot='main')
    bl-video-mv(v-if='videoId', :product-name='product.name', :video-id='videoId')

    .u-mrgn-bottom--6(v-if='product.condition')
      h3.c-information__subtitle Kondisi Barang
      .c-label(:class='{ "c-label--pink": product.condition.toLowerCase() === "baru" }')
        | {{ product.condition }}

    .u-mrgn-bottom--6.u-txt--base
      h3.c-information__subtitle Spesifikasi
      bl-collapsible-detail-mv
        table.c-information__table(slot='content')
          colgroup
            col
            col(:style='{ width: "12px" }')
          tbody
            template(v-for='spec in commonSpecs')
              row-specification-av(
                v-if='getSpecValue(spec.name, true)',
                :key='spec.name',
                :label='spec.label',
                :value='getSpecValue(spec.name, true)'
              )

            template(v-for='spec in productSpecs')
              row-specification-av(
                v-if='spec.value',
                :key='spec.name',
                :label='spec.name',
                :value='spec.value'
              )

    .c-information__description.u-txt--base
      h3.c-information__subtitle Deskripsi

      .c-information__widget(data-testid="ttl-24-widget")
        .js-24ttl

      bl-collapsible-detail-mv(:max-height='285')
        template(slot='content')
          .c-information__description-txt(v-html='productDescription', v-once)

      a.c-product-details__report(
        :href='reportUrl',
        rel='noopener nofollow noreferrer',
        target='_blank'
      )
        bl-icon-av(:icon='productDetailTvInjection.icon.icoFlag' color="#999")
        | &#32;Laporkan Barang

    .u-mrgn-top--9(v-if='product.tagPages && product.tagPages.length')
      h3.c-information__subtitle Tags
      bl-tag-mv(:tags='product.tagPages')
</template>

<script>
import { getYoutubeId } from '@bukalapak/toolbox-helper/url-helper';
import { BlIconAv } from '@bukalapak/bazaar-visual-v1';
import BlListDefinitionMv from '@bukalapak/bazaar-dweb/dist/components/BlListDefinitionMv';
import { get } from 'lodash';
import { mapActions } from 'vuex';

import BlVideoMv from '~pdp-mv/BlVideoMv';
import BlTagMv from '~pdp-mv/BlTagMv';
import { weightConverter, formatDescription } from '~pdp-shared-js/helpers/product-helper';
import { isArray } from '~shared-js/helpers/common-helpers';
import RowSpecificationAv from './components/RowSpecificationAv';
import {
  fetch24TtlConfig,
  fetch24TTLScript,
  is24TtlSeller,
} from '~shared/javascripts/product-detail-page/helpers//24ttl';

const BlCollapsibleDetailMv = () =>
  import(/* webpackChunkName: "bl-collapsible-detail-mv" */ '~pdp-mv/BlCollapsibleDetailMv');
const BlSectionMv = () => import(/* webpackChunkName: "bl-section-mv" */ '~pdp-mv/BlSectionMv');

export default {
  name: 'BlInformationOv',

  components: {
    BlVideoMv,
    BlCollapsibleDetailMv,
    BlSectionMv,
    BlListDefinitionMv,
    BlIconAv,
    BlTagMv,
    RowSpecificationAv,
  },

  inject: ['productDetailTvInjection'],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      commonSpecs: [
        {
          label: 'kategori',
          name: 'category',
        },
        {
          label: 'berat',
          name: 'weight',
        },
        {
          label: 'asal barang',
          name: 'imported',
        },
        {
          label: 'katalog',
          name: 'catalog',
        },
      ],
      is24TtlSeller: false,
    };
  },

  computed: {
    reportUrl() {
      if (this.product.url) {
        const { url } = this.product;
        const name = url.slice(url.indexOf(this.product.id));
        const comebackName = url.slice(url.indexOf('/p/'));

        return `/products/${name}/report_by_user?comeback=${comebackName}&reskin=1`;
      }

      return '';
    },
    videoId() {
      return getYoutubeId(this.product.videoUrl || '');
    },
    productSpecs() {
      const { specs } = this.product;
      if (specs) {
        return Object.keys(specs).reduce((acc, spec) => {
          const specValue = specs[spec];
          if (specValue && specValue !== 'null') {
            // if specValue is empty array, return acc
            if (isArray(specValue) && !specValue[0]) {
              return acc;
            }
            return [
              ...acc,
              {
                name: spec,
                value: specValue,
              },
            ];
          }
          return acc;
        }, []);
      }
      return [];
    },
    productDescription() {
      return formatDescription(this.product.description);
    },
  },

  async mounted() {
    if (this.product.id) {
      // Delay execution of this function
      setTimeout(() => {
        this.getProductCatalog();
      }, 2000);
    }

    // enable container for 24ttl content
    this.load24TtlContent();
  },

  methods: {
    ...mapActions('productStore', ['getProductCatalog']),

    getSpecValue(key, isCommon = false) {
      if (isCommon) {
        if (key === 'imported') {
          return this.product[key] ? 'Impor' : 'Lokal';
        }
        if (key === 'catalog') {
          if (this.product[key] && this.product[key].id) {
            return this.product[key];
          }
          return '';
        }
        if (key === 'weight') {
          const weight = this.product[key];
          return weightConverter(weight, 'gr', true);
        }
        if (key === 'category') {
          return { ...get(this.product, key), isNoFollow: true };
        }

        return get(this.product, key);
      }

      return this.product.specs[key];
    },

    showAndPlayVideo() {
      this.showYtVideo = true;
    },

    async load24TtlContent() {
      try {
        this.ttl24Config = await fetch24TtlConfig();
        this.is24TtlSeller = is24TtlSeller(this.ttl24Config, this.product);
        if (this.is24TtlSeller) {
          const brand = this.ttl24Config.data[this.product.store.id];
          // fetch 24 ttl content to be put to container
          fetch24TTLScript(brand, this.product.id);
        }
      } catch (error) {
        // intended to not do anything
      }
    },
  },
};
</script>

<style lang="scss" src="./BlInformationOv.style.scss"></style>

import { neo } from '~pdp/js/modules/api-modules';

export const fetch24TtlConfig = async () => {
  try {
    const configKey = 'ttl24-config';
    const res = await neo.$configs.postConfigs({
      data: { id: [configKey] },
    });

    const config = res.data.find((c) => c.id === configKey);

    return config;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const is24TtlSeller = (config, product) => {
  const keys = Object.keys(config.data);
  const isEligibleSeller = keys.some((key) => key === product.store.id.toString());
  return isEligibleSeller;
};

export const fetch24TTLScript = async (brand, productid) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;

  script.innerHTML = `
    (function(w,d,s,o){var f=d.getElementsByTagName(s)[0];var j=d.createElement(s);
      w.TTLStreamReady = new Promise((resolve) => {
        j.async=true;j.src= 'https://content.24ttl.stream/widget.js';f.parentNode.insertBefore(j,f);
        j.onload = function () {
          w.ttlStream = new TTLStream(o); resolve(w.ttlStream);
        };
      });
    })(window,document,'script',{});
    TTLStreamReady.then(() => {
      ttlStream.findAndInsert({
        "brand": "${brand}",
        "productId": "${productid}",
        "retailerDomain": "bukalapak.com",
        "templateType": "master_template",
        "resultType": "html",
        "contentType": "minisite",
        "el": ".js-24ttl",
        "language": "id_id"
      })
    });
  `;
  document.body.appendChild(script);
};
